import React from "react";
import Text from "@/bit/components/components.text";
import Image from "@/bit/components/components.c-image";

const LAYOUT_TYPE = {
  COMPACT: "compactRow",
  COMPACT_REVERSE: "compactRowReverse",
  FLUID: "fluidRow",
  FLUID_REVERSE: "fluidRowReverse",
};

const InformationModule = (props) => {
  const { img, altSEO, layout, backgroundColor } = props;

  const titlePrint = () => {
    return (
      <div className="title">
        {props["Text-1"] && <Text {...props["Text-1"]} />}
        {props["Text-2"] && (
          <div className="description">
            <Text {...props["Text-2"]} />
          </div>
        )}

        <style jsx>{`
          .title {
            margin-bottom: 12px;
            .description {
              margin-top: 8px;
            }
          }
        `}</style>
      </div>
    );
  };

  const subTitlePrint = () => {
    return (
      <div className="subTitle">
        {props["Text-3"] && <Text {...props["Text-3"]} />}
        {props["Text-4"] && (
          <div className="description">
            <Text {...props["Text-4"]} />
          </div>
        )}
        <style jsx>{`
          .subTitle {
            margin: 12px 0px;
            .description {
              margin-top: 8px;
              :global(li) {
                line-height: 150%;
              }
            }
          }
        `}</style>
      </div>
    );
  };

  return (
    <>
      <div className={`information-module ${backgroundColor || "white"}`}>
        <div className="container">
          {(layout === LAYOUT_TYPE.COMPACT ||
            layout === LAYOUT_TYPE.COMPACT_REVERSE) &&
            titlePrint()}
          <div
            className={`wrapper-information ${
              layout === LAYOUT_TYPE.FLUID_REVERSE ||
              layout === LAYOUT_TYPE.COMPACT_REVERSE
                ? ""
                : "reverse-position"
            }`}
          >
            <div className="wrapper-son wrapper-image">
              <div className="image-border">
                <Image
                  src={img}
                  alt={altSEO}
                  priority
                  width="496"
                  height="438"
                  objectFit="cover"
                  cyData="InformationModule Image-1"
                />
              </div>
            </div>
            <div className="wrapper-son wrapper-text">
              {(layout === LAYOUT_TYPE.FLUID ||
                layout === LAYOUT_TYPE.FLUID_REVERSE) &&
                titlePrint()}
              {subTitlePrint()}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @import "styles/colors.scss";

        .information-module {
          padding: 24px 0;
          &.white {
            background-color: white;
          }
          &.grey {
            background-color: $color-gp--grey1;
          }
          .wrapper-son {
            padding: 12px 0px;
          }
          @media screen and (min-width: 768px) {
            .reverse-position {
              flex-direction: row-reverse;
            }
            .wrapper-information {
              display: flex;
              justify-content: space-between;
              .wrapper-son {
                margin: 24px 0px;
              }
              .wrapper-image {
                width: calc(35% - 8px);
              }
              .wrapper-text {
                width: calc(65% - 8px);
              }
            }
          }
          .wrapper-information {
            .wrapper-image {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              .image-border {
                display: flex;
                align-items: center;
                border-radius: 16px;
                overflow: hidden;
              }
            }
          }
        }
      `}</style>
    </>
  );
};

export default InformationModule;
