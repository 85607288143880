import React, { Fragment } from 'react';
import {
  CharacteristicsItems,
  CharacteristicsTitle,
} from '@/bit/components/components.characteristics';
import { StepListItems } from '@/bit/components/components.step-list';
import SimplePhone from '@/bit/components/components.simple-phone';

const Banner = ({ bannerInfo, phone, className }) => {
  return (
    <>
      {bannerInfo[0]?.component === 'characteristics' &&
        Array.isArray(bannerInfo) &&
        bannerInfo.map((bannerItem, i) => (
          <Fragment key={`key-${i}`}>
            <CharacteristicsTitle
              {...bannerItem}
              className={className}
              index={i}
            />
            <CharacteristicsItems
              {...{ ...bannerItem, layout: 'tarifas' }}
              className={className}
              index={i}
            />
            {i < bannerInfo.length - 1 && <div className="banner-gap" />}
          </Fragment>
        ))}
      {bannerInfo?.component === 'stepList' && (
        <StepListItems {...bannerInfo} className={className} />
      )}
      {phone && (
        <div className={className}>
          <SimplePhone {...phone} />
        </div>
      )}
      <style jsx>{`
        :global(.main-characterisctics--title) {
          margin-bottom: 1.5rem;
        }

        :global(.fibre-pro-section-left) {
          grid-column: 1;
        }

        :global(.fibre-pro-section-right) {
          grid-column: 2;
        }

        :global(.banner-left) {
          display: flex;
          flex-flow: column nowrap;
          gap: 0rem;

          :global(.banner-characs) {
            display: none;
          }

          :global(.main-characterisctics--title) {
            margin-bottom: 0.65rem;
            align-self: flex-end;
          }
          :global(.text) {
            line-height: 120%;
          }
        }

        :global(.banner-right) {
          .text {
            line-height: 120%;
          }
        }

        :global(.main-characterisctics--row) {
          gap: 0.4rem;
          // margin-bottom: 1rem;

          & > :global(*) {
            margin-bottom: 4px;
          }

          :global(.main-characterisctics--row--icon) {
            padding: 0px;
            min-width: 32px;
          }
        }

        @media screen and (max-width: 768px) {
          :global(.banner-left) {
            width: 100%;
            gap: 1rem;
          }

          :global(.banner-right) {
            width: 100%;
          }
        }

        @media screen and (max-width: 768px) {
          :global(.banner-left) {
            width: 45%;
          }

          :global(.banner-charac) {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

export default Banner;
