import React, { useState } from "react";
import dynamic from "next/dynamic";
import Text from "@/bit/components/components.text";
import Image from "@/bit/components/components.c-image";
import Button from "@/bit/components/components.button";
import Step from "./step";
import C2C from "./c2c";

const Icon = dynamic(() => import("@/bit/components/components.icon-glyph"));

const OPTIONS = [
  {
    title: "¿Que tipo de vivienda tienes?",
    options: [
      "Adosado o chalet",
      "Piso en altura",
      "Ático o bajo",
      "Segunda residencia",
    ],
    field: "vivienda",
  },
  {
    title: "¿Cuántos metros cuadrados tiene tu vivienda?",
    options: ["Menos de 90 m2", "Más de 91 m2"],
    field: "superficie",
  },
  {
    title: "¿Tienes alarma contratada actualmente?",
    options: ["No", "Sí, Prosegur", "Sí, Securitas", "Sí, otros"],
    field: "servicio",
  },
  {
    title: "¿Cúanto tiempo está tu vivienda vacía?",
    options: [
      "Menos de 3 horas al día",
      "Mas de 3 horas al día",
      "Siempre hay alguien",
    ],
    field: "ausencias",
  },
  {
    title: "¿Han robado alguna vez en tu vivienda?",
    options: ["Sí", "No"],
    field: "seguridad",
  },
];

const COMPONENT_NAME = "cotizador";

const CotizadorMPA = () => {
  const [numberStep, setNumberStep] = useState(1);
  const [fields, setFields] = useState({});
  const [finalStep, setFinalStep] = useState(null);

  const taggingInfo = {
    componentName: COMPONENT_NAME,
    componentVariation: "all",
    componentProduct: "mpa",
  };

  const clickOption = ({ field, fieldValue, step }) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: fieldValue,
    }));
    setNumberStep(step + 1);
  };

  const tryAgain = () => {
    setNumberStep(1);
    setFields({});
    setFinalStep(null);
  };

  return (
    <div id="cotizador-alarmas">
      <div className="container">
        <div id="cotizador-display">
          {numberStep <= OPTIONS.length && (
            <div id="content" data-cy="cotizador-alarmas pasos">
              <Text
                text="Configura tu alarma y calculamos su presupuesto"
                size="t2"
                color="blue_dark"
                fontType="font-bold"
                cyData="cotizador-alarmas Title"
              />
              <div id="pasos" data-cy="cotizador-alarmas pasos">
                {OPTIONS.map((value, key) => {
                  return (
                    <>
                      <span
                        className={`pasos ${key == numberStep - 1 ? "active" : key < numberStep ? "border-active" : ""}`}
                        onClick={() => {
                          key < numberStep
                            ? setNumberStep(key + 1)
                            : "javascript:void(0);";
                        }}
                      >
                        {key > numberStep - 2 ? (
                          <Text
                            kind="span"
                            text={key + 1}
                            size="caption"
                            color={key === numberStep - 1 ? "white" : "grey5"}
                            fontType="font-bold"
                          />
                        ) : (
                          <Icon
                            id="check"
                            height={16}
                            color="blue_a"
                            title={`Paso ${key + 1}`}
                          />
                        )}
                      </span>
                      {key < OPTIONS.length - 1 && (
                        <span
                          className={`barrita ${key < numberStep - 1 ? "active" : ""}`}
                        />
                      )}
                    </>
                  );
                })}
              </div>
              <Step
                {...OPTIONS[numberStep - 1]}
                number={numberStep}
                clickOption={clickOption}
                fields={fields}
              />
            </div>
          )}
          {numberStep > OPTIONS.length && !finalStep && (
            <C2C taggingInfo={taggingInfo} setFinalStep={setFinalStep} />
          )}
          {finalStep === "ok" && (
            <div>
              <Icon id="bola-ok" height={48} color="blue_a" />
              <div style={{ marginBottom: "24px", marginTop: "16px" }}>
                <Text
                  text="¡Gracias!"
                  size="t2"
                  color="blue_dark"
                  fontType="font-bold"
                  cyData="cotizador-alarmas Title"
                />
              </div>
              <div style={{ marginBottom: "24px" }}>
                <Text
                  kind="p"
                  text="Hemos recibido tu solicitud con éxito"
                  size="t6"
                  color="blue_dark"
                  fontType="font-bold"
                  data-cy="cotizador-alarmas Title"
                />
              </div>
              <Text
                text="En breve nos pondremos en contacto contigo. Nuestro horario de atención es de 9.00 a 22:00 h"
                size="t7"
                color="grey5"
                fontType="font-regular"
                cyData="cotizador-alarmas C2C Subtitle"
              />
            </div>
          )}
          {finalStep === "ko" && (
            <div>
              <Icon id="alerta-m" height={48} color="orange" />
              <div style={{ marginBottom: "24px", marginTop: "16px" }}>
                <Text
                  text="Ha ocurrido un error"
                  size="t2"
                  color="blue_dark"
                  fontType="font-bold"
                  cyData="cotizador-alarmas Title"
                />
              </div>
              <Text
                text="Algo ha ido mal y no hemos podido recoger tus datos. Por favor, vuelve a intentarlo."
                size="t7"
                color="grey5"
                fontType="font-regular"
                cyData="cotizador-alarmas C2C Subtitle"
              />
              <div style={{ marginTop: "24px" }}>
                <Button
                  label="Volver al inicio"
                  onClick={() => {
                    tryAgain();
                  }}
                  taggingInfo={{
                    ...taggingInfo,
                    componentContent: "te-llamamos",
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div id="cotizador-image">
          <div className="wrapper-image">
            <Image
              src="/content/dam/movistar/alarmas/alarmas-casas-dispositivos-seguridad.jpg"
              width="392"
              alt="Movistar Prosegur Alarmas"
              title="Movistar Prosegur Alarmas"
              height="294"
              priority
              objectFit="cover"
              cyData="InformationModule Image-1"
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        @import "styles/colors.scss";

        #cotizador-alarmas {
          background-color: $color-gp--grey1;
          margin: auto;
          padding: 30px 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          .container {
            display: flex;
          }
          @media screen and (max-width: 768px) {
            flex-direction: column;
            .container {
              display: block;
            }
          }
          input[type="radio"] {
            display: none;
          }
          #cotizador-image {
            position: relative;
            width: 40%;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
            .wrapper-image {
              display: flex;
              justify-content: center;
              align-items: flex-start;
            }
          }
          #cotizador-display {
            width: 60%;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
          #pasos {
            width: 100%;
            margin: 50px auto;
            display: flex;
            position: relative;
            align-items: center;
            span :not(:last-child) {
              margin-right: 8px;
            }
            @media screen and (max-width: 768px) {
              width: fit-content;
            }
            .barrita {
              top: 12px;
              background-color: $color-gp--grey3;
              height: 5px;
              width: 30px;
              display: inline-block;
              border-radius: 4px;
              transition: 750ms;
              @media screen and (max-width: 768px) {
                width: 15px;
              }
            }
            .barrita.active {
              background-color: $color-gp--blue_a;
            }
            .pasos-check {
              display: flex;
              align-items: center;
            }
            .pasos {
              width: 32px;
              height: 32px;
              display: block;
              border-radius: 50%;
              background-color: white;
              border: solid 1.5px $color-gp--grey5;
              position: relative;
              z-index: 2;
              transition: 750ms;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .pasos.active {
              background-color: $color-gp--blue_a;
              border: solid 1.5px $color-gp--blue_a;
              cursor: pointer;
            }
            .border-active {
              border: solid 1.5px $color-gp--blue_a;
              background: transparent;
              cursor: pointer;
            }
            .progressBar {
              content: "";
              position: absolute;
              top: 12px;
              background-color: $color-gp--blue_a;
              height: 5px;
              width: 0%;
              display: block;
              transition: 750ms;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default CotizadorMPA;
