import React from "react";
import TextInfo from "@/bit/components/components.text";

const Title = (props) => {
  return (
    <>
      {props["Text-1"] && props["Text-1"].text && (
        <div className={`${props?.className} steps-characteristics--title`}>
          <TextInfo {...props["Text-1"]} cyData="MainCharacteristic Title" />
          <style jsx>{`.steps-characteristics--title {
  margin-bottom: 1.5rem;
}
`}</style>
        </div>
      )}
    </>
  );
};
export default Title;
