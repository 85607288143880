import React from "react";

import Title from "../title";
import Item from "./item";

const StepListItems = (props) => {
  const characKeys = Object.keys(props).filter((key) =>
    /^Charac-\d+$/.test(key)
  );
  const characValues = characKeys.map((key) => props[key]);

  return (
    <>
      <Title {...props} />
      {characValues &&
        Array.isArray(characValues) &&
        characValues.length > 0 &&
        characValues.map((charac, i) => (
          <Item
            className={props?.className}
            charac={charac}
            index={i}
            displayLine={i < characValues.length - 1}
            key={`charact-item-${i}`}
          />
        ))}
    </>
  );
};

export default StepListItems;
