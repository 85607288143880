import React from "react";
import TextInfo from "@/bit/components/components.text";

const Item = ({ className, charac, index, displayLine }) => {
  return (
    <div
      className={`${className} steps-characteristics--row`}
      key={`steps-characteristics--${index}`}
      data-cy={`Step-List-Item Step-List-Item`}
    >
      <div className="steps-characteristics--row--icon">
        <div className="steps-characteristics--row--iconRight">
          <p>{index + 1}</p>
        </div>
        {displayLine && <div className="steps-characteristics--row--gap"></div>}
      </div>
      <div className="steps-characteristics--row--text">
        {charac["Text-1"] && charac["Text-1"].text && (
          <TextInfo
            {...charac["Text-1"]}
            cyData={`MainCharacteristic-${index} Title`}
          />
        )}
        {charac["Text-2"] && charac["Text-2"].text && (
          <TextInfo
            {...charac["Text-2"]}
            cyData={`MainCharacteristic-${index} Description`}
          />
        )}
      </div>
      <style jsx>{`.steps-characteristics--row--text {
  min-height: 2rem;
  margin-bottom: 1rem;
  :global(h3) {
    margin-bottom: 0.25rem;
  }
  :global(a) {
    color: #0079bb;
  }
  :global(a:hover) {
    color: #80cef9;
  }
}
.steps-characteristics--banner-gap {
  min-height: 20px;

  :global(span) {
    border-left: 4px solid #dddddd;
    margin-left: 15px;
    overflow: hidden;
    height: auto;
    position: absolute;
    color: transparent !important;
    min-height: 25px;
    padding: 6px;
  }
}

.steps-characteristics--banner-gap-2 {
  min-height: 20px;
  :global(span) {
    border-left: 4px solid #dddddd;
    margin-left: 15px;
    height: auto;
    position: absolute;
    color: transparent !important;
    min-height: 25px;
    padding: 6px;
  }
}
.steps-characteristics--banner-gap-last {
  width: 35px;
  opacity: 0;
  margin-left: 15px;
  display: inline;
  vertical-align: -webkit-baseline-middle;
  :global(span) {
    display: none;
  }
}

.steps-characteristics--row {
  display: flex;
  margin-bottom: 4px;
  gap: 1rem;

  .steps-characteristics--row--icon {
    display: flex;
    align-items: stretch;
    position: relative;

    .steps-characteristics--row--iconRight {
      background: #0079bb 0% 0% no-repeat padding-box;
      display: flex;
      height: 32px;
      width: 32px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      z-index: 1;
      :global(p) {
        color: white;
      }
    }
  }

  .steps-characteristics--row--gap {
    position: absolute;
    height: calc(100% + 16px);
    width: 4px;
    background-color: rgb(221, 221, 221);
    opacity: 1;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }
}
`}</style>
    </div>
  );
};

export default Item;
