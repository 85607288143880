import React from "react";
import StepListTitle from "../title";
import StepListItems from "../step-list-items";

const StepList = (props) => {
  return (
    <div className="steps-characteristics">
      <StepListTitle {...props} />
      <StepListItems {...props} />
      <style jsx>{`.steps-characteristics {
  display: flex;
  flex-direction: column;
}
`}</style>
    </div>
  );
};

export default StepList;
