import React from "react";
import Text from "@/bit/components/components.text";
import CustomLink from "@/bit/components/components.custom-link";

const COMPONENT_NAME = "step";

const step = ({ title, options, number, field, clickOption, fields }) => {
  const handleClick = (fieldValue) => {
    clickOption({ field, fieldValue, step: number });
  };
  const taggingInfo = {
    componentContent: "",
    componentVariation: "all",
    componentName: COMPONENT_NAME,
  };

  return (
    <div
      className="step"
      id={`pregunta-${number}`}
      data-cy={`CotizadorMPA Pregunta-${number}`}
    >
      <div className="wrapper-title">
        <Text
          kind="p"
          text={title}
          size="t6"
          color="blue_dark"
          fontType="font-bold"
          data-cy={`CotizadorMPA Title Pregunta-${number}`}
          style={{ margin: "12px 0px" }}
        />
      </div>
      <div className="respuestas" data-cy={`CotizadorMPA Respuestas-${number}`}>
        {options?.map((option) => {
          return (
            <CustomLink
              onClick={() => {
                handleClick(option);
              }}
              taggingInfo={{
                ...taggingInfo,
                componentContent: `step-${number}`,
              }}
              className={`stepCotizador ${fields?.[field] === option ? "active" : ""}`}
            >
              <Text
                kind="span"
                text={option}
                size="caption"
                color={fields?.[field] === option ? "blue_a" : "grey6"}
                fontType="font-regular"
              />
            </CustomLink>
          );
        })}
      </div>
      <style jsx>{`
        @import "styles/colors.scss";
        .step {
          .wrapper-title {
            margin: 12px 0px 24px 0px;
            :global(p) {
              @media screen and (max-width: 768px) {
                text-align: center;
              }
            }
          }
          :global(.stepCotizador) {
            border: 1.5px solid $color-gp--grey4;
            padding: 6px 16px;
            border-radius: 8px;
            margin: 4px 4px;
            @media screen and (max-width: 768px) {
              margin: 10px auto;
              display: block;
            }
          }
          @media screen and (min-width: 768px) {
            :global(.stepCotizador:hover) {
              border: 1.5px solid $color-gp--blue_a;
              :global(.text) {
                color: $color-gp--blue_a !important;
              }
            }
          }
          :global(.active) {
            border: 1.5px solid $color-gp--blue_a;
            background-color: #f5fcff;
          }
        }
      `}</style>
    </div>
  );
};

export default step;
