import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text, {
  TEXT_COLOR,
  TEXT_SIZE,
  TEXT_KIND,
} from '@/bit/components/components.text';
import { sendTaggingInfo } from './utils';
import { maskSeparation as applyMaskSeparation } from './utils';
import { calculateNewCaret } from './utils';

export const COMPONENT_NAME = 'input-form';
export const COMPONENT_THEME = {
  BOX: 'input-form--default',
  NO_BOX: 'input-form--no-box',
};
function Input({
  className,
  id,
  name,
  label,
  theme,
  mask,
  info,
  error,
  value,
  onFocus,
  onChange,
  onBlur,
  autoComplete,
  loaded,
  showError,
  readOnly,
  sendTagging,
  type,
}) {
  const [inputValue, setInputValue] = useState('');
  const [key, setKey] = useState('');
  const taggingInfo = {
    componentVariation: 'all',
    componentProduct: '',
    componentName: 'input',
    componentContent: label,
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleOnFocus = () => {
    if(sendTagging){
      sendTaggingInfo(taggingInfo, 'focus');
    }
    onFocus();
  };

  const inputOnKeyDown = (e) => {
    setKey(e.key);
  };
  const handleOnChange = (event) => {
    const currentValue = event.target.value;
    let maskedValue = currentValue;
    if (mask) {
      const { target } = event;
      let newCaretPosition = target.selectionStart;
      const { maxLength, separationLength, separator } = mask;
      maskedValue = applyMaskSeparation(
        currentValue,
        maxLength,
        separationLength,
        separator
      );
      newCaretPosition = calculateNewCaret(
        key,
        newCaretPosition,
        separationLength
      );
      target.value = maskedValue;
      target.setSelectionRange(newCaretPosition, newCaretPosition);
    }
    setInputValue(maskedValue);
    onChange(event);
  };

  const handleOnBlur = (event) => {
    onBlur(event);
  };
  const labelClassName = error
    ? 'input__label input__label--error'
    : 'input__label';
  return (
    <div className={`input ${className}`} data-cy={id}>
      <div className={`skeleton skeleton-input ${loaded ? 'loaded' : ''}`} />
      <div className={theme}>
        <input
          className="input__field"
          id={id}
          data-cy={`${id}__input`}
          name={name}
          value={inputValue}
          label={label}
          type={type}
          placeholder=" "
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onKeyDown={inputOnKeyDown}
          onFocus={handleOnFocus}
          autoComplete={autoComplete}
          readOnly={readOnly}
        />
        <label htmlFor={id} className={labelClassName} data-cy={`${id}__label`}>
          {label}
        </label>
      </div>
      {showError && loaded && error && (
        <Text
          className="input__error"
          text={error}
          color={TEXT_COLOR.RED}
          kind={TEXT_KIND.SPAN}
          size={TEXT_SIZE.CAPTION3}
          cyData={`${id}__error`}
        />
      )}
      {loaded && info && !error && (
        <Text
          className="input__error line-height-120"
          text={info}
          color={TEXT_COLOR.GREY5}
          kind={TEXT_KIND.SPAN}
          size={TEXT_SIZE.CAPTION3}
          cyData={`${id}__info`}
        />
      )}
      <style jsx>{`@import "styles/colors.scss";
  .input {
    position: relative;
  }
  .input-form--default {
    background-color: $color-gp--white;
    border: 1px solid $color-gp--grey3;
    border-radius: 4px;
    height: 56px;
    width: 100%;
    position: relative;
  }
  .input-form--default {
    background-color: $color-gp--white;
    border-radius: 4px;
    height: 56px;
    width: 100%;
    position: relative;
  }
  .skeleton-input {
    position: absolute;
    border-radius: 4px;
    top: 0;
    left: 0;
  }
  .input__field {
    border: 0;
    outline: none !important;
    font-size: 16px;
    font-family: Regular;
    color: $color-gp--grey6;
    padding: 24px 20px 8px 12px;
    background: transparent;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
  }

  .input__field::placeholder {
    color: transparent;
  }
  .input__label {
    position: absolute;
    font-size: 16px;
    font-family: Regular;
    cursor: text;
    pointer-events: none;
    white-space: nowrap;
    color: $color-gp--grey5;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input__field:placeholder-shown ~ .input__label {
    top: 17px;
    left: 12px;
  }
  .input__field:not(:placeholder-shown) ~ .input__label {
    top: 9px;
    left: 12px;
    font-size: 12px;
    &--error {
      color: $color-gp--red;
    }
  }
  .input__field:focus ~ .input__label {
    top: 9px;
    left: 12px;
    font-size: 12px;
    color: #0079BB;
    &--error {
      color: $color-gp--red;
    }
  }
  .input__field:focus {
    caret-color: #0079BB;
  }
  :global(.input__error) {
    padding: 4px 0 0 12px;
  }
  /* remove styles from autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: $color-gp--grey6 !important;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
  /* remove styles from type number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  @media screen and (min-width: 768px) {
    .input-form--default {
      height: 48px;
    }
    .input-form--no-box {
      height: 48px;
    }
    .input__field {
      padding: 20px 20px 4px 12px;
    }
    .input__field:placeholder-shown ~ .input__label {
      top: 14px;
    }
    .input__field:not(:placeholder-shown) ~ .input__label {
      top: 4px;
    }
    .input__field:focus ~ .input__label {
      top: 4px;
    }
    label,
    .input__field:focus ~ .input__label {
      top: 4px;
      left: 12px;
    }
  }
`}</style>
      </div>
  );
}
Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  theme: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  onFocus: PropTypes.func,
  mask: PropTypes.object,
  error: PropTypes.string,
  info: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  loaded: PropTypes.bool,
  showError: PropTypes.bool,
  readOnly: PropTypes.bool,
  sendTagging: PropTypes.bool,
  type: PropTypes.string,
};

Input.defaultProps = {
  className: '',
  label: 'input',
  theme: COMPONENT_THEME.BOX,
  error: '',
  info: '',
  mask: null,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  autoComplete: 'on',
  value: '',
  loaded: true,
  showError: true,
  readOnly: false,
  sendTagging: false,
  type: 'text',
};
export default Input;
