import React from "react";
import { useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import TextInfo from "@/bit/components/components.text";
import Button from "@/bit/components/components.button";
import Price from "@/bit/components/components.price";
import getColor from "@gcloud-npm/utils.enums.colors";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import CustomLink from "@/bit/components/components.custom-link";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";

const Card = (props) => {
  const { index } = props;

  const taggingInfoMasInfo = {
    componentName: "service-card",
    componentVariation: "all",
    componentContent:
      props["Button-1"] && props["Button-1"].label
        ? props["Button-1"].label
        : "",
    componentProduct: props.alias || "",
    eventType: "click",
  };

  const isWebView = process.env.NEXT_PUBLIC_WEB_VIEW === "true";
  const dispatch = useDispatch();

  const handleProductModal = () => {
    const ProductModal = dynamic(() => import("../modal"));
    const device = getDeviceKindDependsOnViewPort();
    dispatch(
      modalActions.addModal({
        id: "product-modal",
        Element2Show: ProductModal,
        modalProps: {
          closeIdByClickingOutside: "closeAll",
          opacityLayoutColor: "blur",
          closeByClickingOutside: true,
          contentInsideContainer: false,
          contentPosition: "center--center",
          inSceneEffect:
            device === "MOBILE" || device === "TABLET"
              ? "fadeInBottom"
              : "fadeInRight",
          outSceneEffect:
            device === "MOBILE" || device === "TABLET"
              ? "fadeOutBottom"
              : "fadeOutRight",
          scrollAllowed: false,
          createAnchor: false,
        },
        elementProps: {
          ...props["FlyOut-1"],
        },
      })
    );
  };
  return (
    <>
      <div className="service-card" key={index}>
        {props.href && (
          <CustomLink
            {...props["CustomLink-1"]}
            title={props["Text-1"]?.title || ""}
            className="overContent"
            href={props.href}
            taggingInfo={{
              componentContent: props["Text-1"].title,
              componentProduct: props["Text-1"].text || props.alias || "",
              componentVariation: "all",
              eventType: "click",
            }}
            cyData="Card CustomLink-1"
          />
        )}
        <div className="card-content-container">
          <div>
            <div className="service-card-text custom-height-title">
              {props["Text-1"] &&
                props["Text-1"].text &&
                !props["CustomLink-1"] && (
                  <TextInfo
                    {...props["Text-1"]}
                    cyData={`Maincardteristic-${index} Title`}
                  />
                )}
            </div>
            <div className="service-desc">
              {props["Text-2"] && props["Text-2"].text && (
                <TextInfo
                  {...props["Text-2"]}
                  cyData={`Maincardteristic-${index} Description`}
                />
              )}
            </div>
          </div>

          <div>
            <div className="text-pre-price">
              {props["Text-3"] && props["Text-3"].text && (
                <TextInfo {...props["Text-3"]} />
              )}
            </div>
            <div className="price-container">
              {props["Price-1"] && <Price {...props["Price-1"]} />}
            </div>

            <div className="text-post-price">
              {props["Text-4"] && props["Text-4"].text && (
                <TextInfo
                  {...props["Text-4"]}
                  cyData={`Maincardteristic-${index} Description`}
                />
              )}
            </div>
            <div className="service-buy-button">
              {props["Button-1"] &&
                !isWebView &&
                props["Button-1"].label &&
                props["Button-1"].href && (
                  <Button
                    {...props["Button-1"]}
                    taggingInfo={taggingInfoMasInfo}
                    cyData="ServiceCard Buetton-3"
                  />
                )}
              {props["Button-1"] &&
                !isWebView &&
                props["Button-1"].label &&
                !props["Button-1"].href && (
                  <Button
                    {...props["Button-1"]}
                    onClick={handleProductModal}
                    taggingInfo={taggingInfoMasInfo}
                    cyData="ServiceCard Buetton-3 FlyOut"
                  />
                )}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @import "styles/colors.scss";
        .service-card {
          border-radius: 16px;
          border: 1px solid $color-gp--grey2;
          opacity: 1;
          box-sizing: border-box;
          display: flex;
          flex-flow: column nowrap;
          width: 184px;
          height: 100%;
          overflow: hidden;
          position: relative;
          &:hover {
            border: 1px solid $color-gp--grey3;
          }
          @media screen and (max-width: 768px) {
            width: 100%;
            min-width: 184px;
          }

          .service-card-img {
            position: relative;
            text-align: center;
            color: white;
            &.without-image {
              margin-bottom: 22px;
            }
            :global(picture) {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;

              :global(img) {
                border-radius: 0;
              }
            }
          }

          .service-card-recommend {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #0b2739;
            border-radius: 1rem 0;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 1.6px 1rem;

            :global(.text) {
              line-height: inherit;
            }
          }

          .service-card-empty-recommend {
            background-color: #0b2739;
            display: flex;
            text-align: center;
            justify-content: center;
          }

          .card-content-container {
            padding: 1rem;
            flex: 1;
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;

            .service-card-text {
              margin-bottom: 0.5rem;
            }

            .service-card-subtitle {
              margin-bottom: 0.5rem;
            }

            .service-desc {
              flex-wrap: wrap;
              width: auto;
              margin-bottom: 1rem;

              :global(.text) {
                line-height: 1.5rem;
              }

              @media screen and (max-width: 768px) {
                width: 90%;
              }
            }

            .claim-promo {
              padding-top: 0.4rem;
            }

            .claim-promo-empty {
              height: 15px;
            }

            .price-container {
              display: flex;
              gap: 0.75rem;
              padding: 0 0 0.2rem 0;
            }

            .text-post-price {
              min-height: 21px;
            }

            .text-pre-price {
              min-height: 21px;
            }

            .service-promo {
              margin-left: 16px;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              width: 50px;
              height: 22px;
              align-content: center;
              background: ${getColor("RED_DARK")} 0% 0% no-repeat padding-box;
            }

            .service-promo-empty {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              width: 50px;
              height: 22px;
              align-content: center;
              background: white;
            }

            .service-price-text-desc {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              padding-left: 16px;
              padding-right: 16px;
              :global(span) {
                text-decoration: line-through $color-gp--grey5;
              }
            }

            .service-buy-button {
              display: flex;
              padding-top: 1rem;
              position: relative;
              z-index: 2;
              justify-content: space-between;

              @media screen and (max-width: 768px) {
                flex-direction: row;
              }

              :global(a) {
                padding: 0;
              }
            }
          }
        }
      `}</style>
    </>
  );
};

export default Card;
