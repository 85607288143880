import sendGaComponentEvent from '@gcloud-npm/utils.metrics.send-ga-component-event';

export const checkIsDeleteType = (key) => {
  return key === 'Delete';
};
export const checkIsBackspaceType = (key) => {
  return key === 'Backspace';
};
export const isSpaceAdded = (caretPosition, maskedSeparation) => {
  const modulo = caretPosition % (maskedSeparation + 1);
  return modulo === 0;
};
export const calculateNewCaret = (key, caretPosition, separationLength) => {
  let newCaretPosition = caretPosition;
  if (
    checkIsDeleteType(key) &&
    isSpaceAdded(newCaretPosition + 1, separationLength)
  ) {
    newCaretPosition += 1;
  } else if (
    !checkIsDeleteType(key) &&
    !checkIsBackspaceType(key) &&
    isSpaceAdded(newCaretPosition, separationLength)
  ) {
    newCaretPosition += 1;
  }
  return newCaretPosition;
};

export const maskSeparation = (
  value,
  maxLength,
  separationLength,
  separator
) => {
  let newValue = value;
  newValue = newValue.replace(/\D/g, '');
  if (newValue.length > maxLength) {
    newValue = newValue.slice(0, maxLength);
  }
  const regexString = `(\\d{${separationLength}})(?=\\d)`;
  const regex = new RegExp(regexString, 'g');
  newValue = newValue.replace(regex, `$1${separator}`);
  return newValue;
};

export const sendTaggingInfo = (taggingInfo, eventType) => {
  const finalTaggingInfo = {
    ...taggingInfo,
    eventType,
  };
  try {
    const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;
    const isFrontApp = typeof window !== 'undefined';
    const isMetricsDevEnable =
      isFrontApp && window.location.search.indexOf('metrics') > -1;
    if ((isFrontApp && currentEnv !== 'dev') || isMetricsDevEnable) {
      sendGaComponentEvent(finalTaggingInfo);
    }
  } catch (err) {
    throw err;
  }
};