import React from "react";
import Carousel from "@/bit/components/components.carousel";
import TextInfo from "@/bit/components/components.text";
import Card from "./card";

const CardListFlyout = (props) => {
  return (
    <div className="card-container container">
      {props && props["Text-1"] && props["Text-1"].text && (
        <div className="card-container--title">
          <TextInfo {...props["Text-1"]} cyData="RoamingCard Title" />
        </div>
      )}

      <div className="card-container-carousel">
        <Carousel maxPointNum={0} breakpoint={768}>
          {props.cards &&
            Array.isArray(props.cards) &&
            props.cards.length > 0 &&
            props.cards.map((card, index) => (
              <Card {...card} key={`service-card-${index}`} index={index} />
            ))}
        </Carousel>
      </div>
      <style jsx>
        {`
          .card-container {
            margin-top: 1.5rem;
            @media screen and (max-width: 768px) {
              margin-top: 0.75rem;
            }
            &--title {
              margin-bottom: 1rem;
            }

            .card-container-carousel {
              :global(.carousel) {
                :global(.carousel-window) {
                  gap: 24px;
                  @media screen and (max-width: 768px) {
                    gap: 12px;
                  }
                  :global(.carousel-item-wrapper) {
                    width: min-content;
                  }
                }
              }
              .service-card-content {
                display: inline-flex;
                flex-direction: row;
                gap: 24px;
                width: 100%;

                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }

            .card-container-grid {
              display: grid;
              gap: 24px;
              grid-template-columns: repeat(auto-fill, minmax(182px, 1fr));

              @media screen and (max-width: 768px) {
                gap: 12px;
                grid-template-columns: repeat(12, 0fr);
                overflow-x: auto;
                overflow-y: hidden;
              }

              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
        `}
      </style>
    </div>
  );
};

export default CardListFlyout;
