import React, { Fragment } from 'react';
import Banner from './banner';

const FibreProsSection = ({ sectionLeft, sectionRight, simplePhone }) => {
  return (
    <div className="fibre-pros-section" data-cy="fibre-pros-section">
      <div className="fibre-pros-container container">
        <Banner bannerInfo={sectionLeft} className="fibre-pro-section-left" />
        <div className="banner-gap"></div>
        <Banner
          bannerInfo={sectionRight}
          phone={simplePhone}
          className="fibre-pro-section-right"
        />
      </div>

      <style jsx>{`
        .fibre-pros-section {
          background-color: rgb(245, 245, 245);
          margin: 1.5rem 0;

          :global(.main-characterisctics) {
            gap: 0;
          }

          .fibre-pros-container {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            row-gap: 0.7rem;

            margin-bottom: 1rem;
            margin-top: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
          }

          .banner-gap {
            position: static;
            display: block;
            margin: 25px;
            height: 0;
            width: 85%;
            border: 1px solid #dddddd;
            opacity: 1;
          }
        }

        @media screen and (min-width: 768px) {
          .fibre-pros-section {
            .fibre-pros-container {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-auto-flow: column;
              padding: 1rem;
              column-gap: 3rem;
              padding-top: 1rem;
              padding-bottom: 1rem;
              row-gap: 0.5rem;

              .banner-gap {
                margin: 5px 20px;
                width: 1px;
                border: 1px solid #dddddd;
                opacity: 1;
                position: absolute;
                top: 50%;
                left: calc(50% - 1.5rem);
                height: 85%;
                transform: translate(-50%, -50%);
              }
              .banner-right {
                width: 55%;
              }
            }
          }
        }
      `}</style>
    </div>
  );
};

export default FibreProsSection;
