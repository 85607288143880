import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dynamic from "next/dynamic";
import { actions as modalActions } from "@/bit/reducers/reducers.modals-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import Text, {
  TEXT_ALIGN,
  TEXT_COLOR,
  TEXT_DISPLAY,
  TEXT_FONT_WEIGHT,
  TEXT_KIND,
  TEXT_SIZE,
} from "@/bit/components/components.text";
import Button, {
  BUTTON_OPERATION,
  BUTTON_WIDTH,
} from "@/bit/components/components.button";
import sendGaEvent from "@gcloud-npm/utils.metrics.send-ga-event";
import CustomLink from "@/bit/components/components.custom-link";
import Script from "next/script";
import { validateAnyPhone } from "@gcloud-npm/utils.validators";
import { actions as mainActions } from "@/bit/reducers/reducers.main-reducer";
import { getCampaign, RECAPTCHA_KEY } from "@gcloud-npm/utils.c2c";
import Input from "@/bit/components/components.forms.input";

const INPUT_VALUES = {
  phone: {
    value: "",
    valid: false,
  },
};

const INPUT_ERRORS = {
  phone: {
    error: "",
    showError: false,
  },
};

const STATES = {
  OK: "SUCCESS",
  KO: "FAILURE",
  FETCH: "FETCHING",
};

const FORM_STATES = {
  SEND: "formulario-enviado",
  KO: "formulario-ko",
  START: "inicio-formulario",
};

const COMPONENT_NAME = "C2CCotizador";

let Privacidad = null;

const C2CCotizador = ({ taggingInfo, setFinalStep }) => {
  const [waiting, setWaiting] = useState(false);
  const [data, setData] = useState({ ...INPUT_VALUES });
  const [errors, setErrors] = useState({ ...INPUT_ERRORS });
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const { c2cState, context, campaignState, campaignInfo } = useSelector(
    (state) => state.main
  );

  const dispatch = useDispatch();

  const finalTaggingInfo = {
    componentName: COMPONENT_NAME,
    ...taggingInfo,
    componentProduct: "",
  };

  const handleClickConditions = () => {
    const device = getDeviceKindDependsOnViewPort();
    const MODAL_PROPS = {
      contentPosition:
        device !== "MOBILE" ? "center--center" : "center--bottom",
      opacityLayoutColor: "black05",
      inSceneEffect: "fadeInBottom",
      outSceneEffect: "fadeOutBottom",
      closeByClickingOutside: true,
      scrollAllowed: false,
    };
    Privacidad = dynamic(() => import("../privacidad"));
    dispatch(
      modalActions.addModal({
        id: "conditions",
        type: "MODAL",
        elementProps: {},
        Element2Show: Privacidad,
        modalProps: MODAL_PROPS,
      })
    );
  };


  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      sendGaEvent({ ...finalTaggingInfo, componentContent: FORM_STATES.START });
      if (campaignState !== STATES.ok) {
        try {
          getCampaign(context)
            .then((value) => {
              dispatch(
                mainActions.setCampaignC2C({ status: STATES.OK, info: value })
              );
            })
            .catch((error) => {
              dispatch(
                mainActions.setCampaignC2C({ status: STATES.KO, info: null })
              );
            });
        } catch (error) {
          dispatch(
            mainActions.setCampaignC2C({ status: STATES.KO, info: null })
          );
        }
      }
      mounted.current = true;
    } else if (waiting) {
      switch (c2cState) {
        case STATES.OK:
          sendGaEvent({
            ...finalTaggingInfo,
            componentContent: FORM_STATES.send,
          });
          setWaiting(false);
          setFinalStep("ok");
          break;
        case STATES.KO:
          sendGaEvent({
            ...finalTaggingInfo,
            componentContent: FORM_STATES.ko,
          });
          setWaiting(false);
          setFinalStep("ko");
          break;
        default:
          break;
      }
    }
  });

  const setDataFieldsInput = (event) => {
    const validation = validateAnyPhone(event.target.value, { required: true });
    setErrors((prevData) => ({
      ...prevData,
      [event.target.name]: {
        error: validation.errorMsg,
        showError: !validation.validated,
      },
    }));
    setData((prevData) => ({
      ...prevData,
      [event.target.name]: {
        value: event.target.value,
        valid: validation.validated,
      },
    }));
  };

  const onReadyRecaptcha = () => {
    setRecaptchaReady(true);
  };

  const validData = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (!obj[key].valid) {
          return false;
        }
      }
    }
    return true;
  };

  const reconstructData = (obj) => {
    const dataReconstructed = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        dataReconstructed[key] = obj[key].value;
      }
    }

    return dataReconstructed;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validData(data)) {
      setWaiting(true);
      const dataReconstructed = reconstructData(data);
      const dataToSend = {
        phone: dataReconstructed.phone,
      };
      dispatch(mainActions.setSendC2C({ status: STATES.FETCH }));
      try {
        const { sendC2C } = await import("@gcloud-npm/utils.c2c");
        sendC2C(context, campaignInfo, dataToSend)
          .then((value) => {
            dispatch(mainActions.setSendC2C({ status: STATES.OK }));
          })
          .catch((error) => {
            dispatch(mainActions.setSendC2C({ status: STATES.KO }));
          });
      } catch (error) {
        dispatch(mainActions.setSendC2C({ status: STATES.KO }));
      }
    }
    return false;
  };

  return (
    <div
      className="C2CCotizador"
      style={{ pointerEvents: `${waiting ? "none" : "auto"}` }}
    >
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`}
        strategy="lazyOnload"
        onReady={onReadyRecaptcha}
      />
      <section className="sectionModalForm">
        <div className="wrapper-title">
          <Text
            text="Tu presupuesto está casi finalizado"
            size="t2"
            color="blue_dark"
            fontType="font-bold"
            cyData="CotizadorMPA C2C Title"
          />
        </div>
        <div className="wrapper-subtitle">
          <Text
            text="Si nos dejas un teléfono de contacto, te llamaremos para concretar algunos detalles.<br>Así podremos darte un presupuesto gratuito con la mejor opción."
            size="t7"
            color="grey5"
            fontType="font-regular"
            cyData="CotizadorMPA C2C Subtitle"
          />
        </div>
        <div className="wrapperForm">
          <form onSubmit={handleSubmit} id="C2CCotizador" name="C2CCotizador">
            <div className="wrapper-actions">
              <Input
                name="phone"
                label="Móvil de contacto"
                mask="tel"
                onBlur={setDataFieldsInput}
                taggingInfo={{
                  ...finalTaggingInfo,
                  componentContent: "input-phone",
                }}
                showError={errors.phone.showError}
                error={errors.phone.error}
                sendTagging
                required
              />
              <Button
                disabled={!recaptchaReady}
                operation={
                  waiting ? BUTTON_OPERATION.LOADING : BUTTON_OPERATION.NO
                }
                label="Enviar"
                width={BUTTON_WIDTH.DEFAULT}
                buttonTypeHtml="submit"
                taggingInfo={{
                  ...finalTaggingInfo,
                  componentContent: "te-llamamos",
                }}
              />
            </div>
            <div className="wrapper-conditions">
              <CustomLink
                title="Tratamiento de datos"
                onClick={handleClickConditions}
                taggingInfo={{
                  ...finalTaggingInfo,
                  componentContent: "show-condiciones",
                }}
              >
                <Text
                  text="Información sobre privacidad"
                  size={TEXT_SIZE.CAPTION2}
                  align={TEXT_ALIGN.LEFT}
                  display={TEXT_DISPLAY.INLINE}
                  kind={TEXT_KIND.SPAN}
                  color={TEXT_COLOR.BLUE}
                  fontType={TEXT_FONT_WEIGHT.REGULAR}
                />
              </CustomLink>
            </div>
          </form>
        </div>
      </section>
      <style jsx>{`
        .C2CCotizador {
          .wrapper-title {
            margin-bottom: 16px;
          }
          .wrapperForm {
            margin-top: 32px;
          }
          .wrapper-actions {
            max-width: 450px;
            display: flex;
            align-items: flex-start;
            margin: 20px 20px 20px 0px;
            @media screen and (max-width: 768px) {
              display: block;
              :global(button) {
                margin-top: 16px !important;
                width: 100%;
              }
            }
          }
          .wrapper-conditions {
            text-align: left;
            @media screen and (max-width: 768px) {
              text-align: center;
            }
          }
          :global(input) {
            background: white;
          }
          :global(.input) {
            margin-right: 20px;
            width: 100%;
          }
          :global(.wrapper-input) {
            margin: 0 !important;
          }
        }
      `}</style>
    </div>
  );
};

export default C2CCotizador;
